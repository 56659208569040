import React from 'react'

import * as styles from './Footer.module.scss'

export const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.content}>
      {new Date().getFullYear()}
      &nbsp;&copy;&nbsp;
      <span className={styles.bold}>Sky Vigil Care Ltd.</span>
      <br />
      <span>
        Registered in England & Wales
        <br />
        Company Registration Number: 11635689
      </span>
      <br />
      <small>All Rights Reserved.</small>
    </div>
    <div className={styles.spacer} />
  </footer>
)
