import React, { useEffect, useState } from 'react'
import useWindowScroll from '@react-hook/window-scroll'

import * as styles from './Header.module.scss'

import phone from 'images/phone.png'
import email from 'images/email.png'

const SCROLL_THRESHOLD = 100

const links = [
  { title: 'Our Mission', link: '#our-mission', key: 'features' },
  { title: 'Care & Services', link: '#care-services', key: 'services' },
  { title: 'About Us', link: '#about-us', key: 'about' },
  { title: 'Careers & Training', link: '#careers-training', key: 'careers' },
  { title: 'Contact Us', link: '#contact-us', key: 'contact' },
]

const info = [
  { icon: phone, link: 'tel:01452341993', content:'01452 341993' },
  { icon: email, link: 'mailto:info@skyvigilcare.co.uk', content:'info@skyvigilcare.co.uk' },
]

const HeaderLink = ({ link, title, key }) => (
  <li key={key} className={styles.link}>
    <a href={link} className={styles.link__anchor}>
      {title}
    </a>
  </li>
)

const getScrolledText = scrolled => (scrolled ? 'shrunk' : 'maximized')

export const Header = () => {
  const [scrolled, setScrolled] = useState(false)
  const scrollY = useWindowScroll(30)

  useEffect(() => {
    if (!scrolled && scrollY > SCROLL_THRESHOLD) {
      setScrolled(true)
    }

    if (scrolled && scrollY < SCROLL_THRESHOLD) {
      setScrolled(false)
    }
  }, [scrollY])

  return (
    <header className={styles[`container__${getScrolledText(scrolled)}`]}>
      <div className={styles.titleContainer}>
        <h1 className={styles.logo}>SkyVigilCare</h1>
        <p className={styles.info}>
          {info.map(item => (
            <a className={styles.infoItem} href={item.link}>
              <img className={styles.infoItemIcon} src={item.icon} />
              <span className={styles.infoItemContent}>{item.content}</span>
            </a>
          ))
          }
        </p>
      </div>
      <div className={styles.linkContainer}>
        <ul className={styles.links}>{links.map(HeaderLink)}</ul>
      </div>
    </header>
  )
}
