// extracted by mini-css-extract-plugin
export var container = "Header-module--container--0bfw7";
export var container__shrunk = "Header-module--container__shrunk--vz-xI";
export var container__maximized = "Header-module--container__maximized--RDcPe";
export var logo = "Header-module--logo--7bOtT";
export var linkContainer = "Header-module--linkContainer--S+MrL";
export var titleContainer = "Header-module--titleContainer--DqLx9";
export var links = "Header-module--links--4dsZR";
export var link = "Header-module--link--VyWp4";
export var link__anchor = "Header-module--link__anchor--rzHhM";
export var info = "Header-module--info--IEj1S";
export var infoItem = "Header-module--infoItem--cLPny";
export var infoItemIcon = "Header-module--infoItemIcon--Kxdu5";
export var infoItemContent = "Header-module--infoItemContent--mJWb2";